import { useRouter } from 'hooks'
import Link from 'next/link'
// components
import { Image } from 'components/Image'
import { Col, Row } from 'components/Layout'
// assets
import LogoImage from 'assets/images/layout/logo.png'
import BHPLogo from 'assets/images/logo/bhp_horizontal.png'
// styled component
import { Text } from 'components/Text'
import {
    BHPLogoWrapper,
    Border,
    HeaderBarWrapper,
    HeaderLoginButton,
    HeaderSignInButton,
    HeaderWrapper,
    MenuItem,
    MenuNav,
    ProfileDropdownWrapper,
    StreamLogoWrapper,
    StreamerLogoH1,
} from './Header.style'
// --------------------------------------- ----------------------------
import { useUser } from '@auth0/nextjs-auth0'
import { CommonDiv } from 'components/Container'
import AdsGPT from 'components/GPT/AdsGPT'
import ProfileDropdown from 'components/ProfileDropdown'
import { siteSettings } from 'hooks'
import {
    LoginSignupAttemptEvent,
    visitProfileEvent,
} from 'lib/tracking/events/userId'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { getCurrentPageName, getPageSlugName } from 'utils/common-helper'

// Yamata
export const DeskHeader = (props: any) => {
    const { menu, showBHPLogo } = props
    const { move, param, asPath, router, path }: any = useRouter()
    const { user } = useUser()
    const [adKey, setAdKey] = useState(0)
    const handleMenuClick = (to: any) => {
        visitProfileEvent({
            props: {
                pageName: getCurrentPageName(router.pathname),
                message: 'Visit User Profile',
            },
        })
        move(to)
    }

    const paramName = !_.isUndefined(user)
        ? `${user?.first_name ?? ''} ${user?.last_name ?? user?.email}`
        : ''

    const trackLoginSignup = () => {
        LoginSignupAttemptEvent({
            props: {
                pageName: getCurrentPageName(router.pathname),
                message: 'Login / Signup attempt',
            },
        })
    }

    useEffect(() => {
        // Increment the adKey to remount the ad, this is required or page navigation breaks the ad
        setAdKey((prev) => (prev + 1) % 2)
    }, [path])

    return (
        <HeaderWrapper>
            <CommonDiv
                direction="column"
                align="center"
                justify="space-between"
                w={'100%'}
                padding="0px 0px 0px 0px"
            >
                <CommonDiv margin="2px 0">
                    <AdsGPT
                        slot={`desktop-${getPageSlugName(path)}-header`}
                        key={adKey}
                        pos={1}
                    />
                </CommonDiv>

                <HeaderBarWrapper>
                    <MenuNav>
                        {asPath === '/' || asPath.split('/')[1] === 'main' ? (
                            <StreamerLogoH1>
                                <StreamLogoWrapper>
                                    <Link href="/">
                                        <Image
                                            src={LogoImage}
                                            height={42}
                                            width={144}
                                            mode="intrinsic"
                                            alt="Streamer: Watch community sport live streams and replays"
                                        />
                                    </Link>
                                </StreamLogoWrapper>
                            </StreamerLogoH1>
                        ) : (
                            <>
                                <CommonDiv
                                    direction="row"
                                    width="auto"
                                    padding="0px 16px"
                                >
                                    <StreamLogoWrapper>
                                        <Link href="/">
                                            <Image
                                                src={LogoImage}
                                                height={42}
                                                width={144}
                                                mode="intrinsic"
                                                alt="Streamer: Watch community sport live streams and replays"
                                            />
                                        </Link>
                                    </StreamLogoWrapper>
                                </CommonDiv>
                            </>
                        )}

                        <Border />

                        {menu.map((item: any, index: number) => {
                            return (
                                <MenuItem
                                    mode={
                                        asPath === item.path ? 'true' : 'false'
                                    }
                                    key={index}
                                    href={item.path}
                                >
                                    <Text
                                        fColor="white"
                                        fSize={1}
                                        hoverStyle={{
                                            fColor: 'white.200',
                                            hfWeight: '700',
                                        }}
                                    >
                                        {item.title}
                                    </Text>
                                </MenuItem>
                            )
                        })}
                    </MenuNav>

                    <CommonDiv direction="row" align="center">
                        <Row justifyContent="flex-end" alignItems="center">
                            {showBHPLogo && (
                                <BHPLogoWrapper>
                                    <Image
                                        src={BHPLogo}
                                        alt="Foundation Partner BHP"
                                        height={30}
                                        width={145}
                                    />
                                </BHPLogoWrapper>
                            )}
                            {user && (
                                <ProfileDropdownWrapper>
                                    <ProfileDropdown isMobile={false} />
                                </ProfileDropdownWrapper>
                            )}

                            {!user && siteSettings('header_menu.login') && (
                                <Col>
                                    <HeaderLoginButton
                                        data-testid="homepage-login"
                                        ariaLabel="login"
                                        bColor="primary"
                                        bSize="big"
                                        onClick={() => {
                                            handleMenuClick(
                                                `/api/auth/login?returnTo=${asPath}`,
                                            ),
                                                trackLoginSignup()
                                        }}
                                    >
                                        {'Login'}
                                    </HeaderLoginButton>
                                </Col>
                            )}
                            {!user && siteSettings('header_menu.sign_up') && (
                                <Col>
                                    <HeaderSignInButton
                                        bColor="warning"
                                        bSize="big"
                                        onClick={() => {
                                            handleMenuClick(
                                                `/api/auth/signup?returnTo=${asPath}`,
                                            ),
                                                trackLoginSignup()
                                        }}
                                        ariaLabel="homepage-signup"
                                    >
                                        {'Sign up'}
                                    </HeaderSignInButton>
                                </Col>
                            )}
                        </Row>
                    </CommonDiv>
                </HeaderBarWrapper>
            </CommonDiv>
        </HeaderWrapper>
    )
}
